/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// CSS
require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
import 'scss/application'
// JS
import('js/site')
// Images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import NextArrowWhite from "../images/icons/arrow-white-next.png"
import PrevArrowWhite from "../images/icons/arrow-white-prev.png"
import NextArrowBlue from "../images/icons/arrow-blue-next.png"
import PrevArrowBlue from "../images/icons/arrow-blue-prev.png"
require('readmore-js');
import { tinyMce } from "../vendor/tinyMce";
import 'lightgallery'
import 'lg-video'
import 'lg-thumbnail'
import 'lg-zoom'
import mixitup from 'mixitup';

global.toastr = require("toastr")

document.addEventListener("DOMContentLoaded",
function(){
var containerEl = document.querySelector('.container-events');
var mixer;

if (containerEl) {
    mixer = mixitup(containerEl,{
      "animation": {
          "duration": 600,
          "nudge": false,
          "reverseOut": false,
          "effects": "fade scale(0.01) translateZ(5px)"
      }
  });
}


  import("booking").then( booking => {
  })

  $('#expandir_about_home').readmore(
    {
      speed: 75,
      moreLink:'<div class="text-center"><a class="btn btn-flat-down read-more readmore_class boton center-block text-dark bold" style="cursor:pointer;">Read more</a></div>',
      lessLink: '<div class="text-center"><a href="#" class="btn btn-flat-down read-more readless_class boton center-block text-dark bold">Read less</a></div>',
      collapsedHeight: 100
    }
  );

  $('#expandir_readmore_content').readmore(
    {
      speed: 75,
      moreLink:'<div class="text-center"><a class="btn btn-flat-down read-more readmore_class boton center-block text-dark bold" style="cursor:pointer;">Read more</a></div>',
      lessLink: '<div class="text-center"><a href="#" class="btn btn-flat-down read-more readless_class boton center-block text-dark bold">Read less</a></div>',
      collapsedHeight: 100
    }
  );

  tinyMce();
  $("#events-grid").lightGallery({
    mode: 'lg-fade',
    thumbnail: false,
    selector: '.event-photo',
    loop:false
  });

  $("#my_nanogallery2").lightGallery({
    mode: 'lg-fade',
    thumbnail: false,
    loop:false
  });
  $(".floor-galler").lightGallery({
    thumbnail:true,
    mode: 'lg-fade'
  });




});


import("slider").then(slick => {

  $('.horizontal-slider-for').not('.slick-initialized').slick({
      infinite: true,
      slidesToShow: 1,
      asNavFor: '.vertical-slider-menu',
      prevArrow:`<div class="icon-prev-arrow"><img src=${PrevArrowWhite} alt="Prev Arrow" /></div>`,
      nextArrow:`<div class="icon-next-arrow"><img src=${NextArrowWhite} alt="Next Arrow" /></div>`,
       dots:false,
       arrows:true,
      responsive: [
        {
          breakpoint:769,
          settings:{
            dots: true,
            arrows:false
          }
        }
      ]
    })
    $('.vertical-slider-menu').on('click', '.slick-slide', function (e) {
      var $currTarget = $(e.currentTarget),
          index = $currTarget.data('slick-index'),
          slickObj = $('.horizontal-slider-for').slick('getSlick');
          slickObj.slickGoTo(index);

      });
      $('.suites-menu').on('click', '.slick-slide', function (e) {
        var $currTarget = $(e.currentTarget),
            index = $currTarget.data('slick-index'),
            slickObj = $('.suites-for').slick('getSlick');
            slickObj.slickGoTo(index);

        });


      $('.suites-for').not('.slick-initialized').slick({
        infinite: true,
        slidesToShow: 1,
        fade: true,
        cssEase: 'linear',
        asNavFor: '.suites-menu',
        dots:false,
        arrows:false,
        adaptiveHeight: true,
        swipe: false,
      })
      $('.suites-menu').not('.slick-initialized').slick({
        slidesToShow: 7,
        infinite: false,
        asNavFor: '.suites-for',
        arrows:false,
        responsive: [
          {
            breakpoint:769,
            settings:{
              slidesToShow:1,
              dots: false,
              centerMode: false,
              focusOnSelect: true,
              arrows:true,
              infinite:true,
              swipe: false,
              prevArrow:`<div class="slick-prev slick-arrow"><img src=${PrevArrowBlue} alt="Prev Arrow" /></div>`,
              nextArrow:`<div class="slick-next slick-arrow"><img src=${NextArrowBlue} alt="Next Arrow" /></div>`,

            }
          }
        ]

      });

    $('.vertical-slider-menu').not('.slick-initialized').slick({
      slidesToShow: 12,
      infinite: false,
      vertical: true,
      asNavFor: '.horizontal-slider-for',
      arrows:false,
      responsive: [
        {
          breakpoint:769,
          settings:{
            slidesToShow:12,
            adaptiveHeight: true,
            dots: true,
          }
        }
      ]

    });

    $('.dining-for').not('.slick-initialized').slick({
      infinite: false,
      slidesToShow: 1,
      asNavFor: '.dining-slider',
       dots:false,
       arrows:false,
       adaptiveHeight: true,
       responsive: [
        {
          breakpoint:769,
          settings:{
            slidesToShow:1,
            slidesToScroll: 1,
            swipe: true,
            arrows:false,
            infinite:true
          }
        }
      ]
    })
    $('.dining-slider').on('click', '.slick-slide', function (e) {
      var $currTarget = $(e.currentTarget),
          index = $currTarget.data('slick-index'),
          slickObj = $('.dining-for').slick('getSlick');
          slickObj.slickGoTo(index);

      });
    $('.dining-slider').not('.slick-initialized').slick({
      slidesToShow: 4,
      asNavFor: '.dining-for',
      arrows:false,
      infinite:false,
      swipe: false,
      responsive: [
        {
          breakpoint:769,
          settings:{
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.dining-for',
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            arrows:true,
            infinite:true,
            prevArrow:`<div class="slick-prev slick-arrow"><img src=${PrevArrowBlue} alt="Prev Arrow" /></div>`,
            nextArrow:`<div class="slick-next slick-arrow"><img src=${NextArrowBlue} alt="Next Arrow" /></div>`,
          }
        }
      ]

    })

    $('.general-slider').not('.slick-initialized').slick({
      slidesToShow: 1,
      arrows:true,
      infinite:true,
      prevArrow:`<div class="icon-prev-arrow"><img src=${PrevArrowWhite} alt="Prev Arrow" /></div>`,
      nextArrow:`<div class="icon-next-arrow"><img src=${NextArrowWhite} alt="Next Arrow" /></div>`,
      dots:false,
      responsive: [
        {
          breakpoint:769,
          settings:{
            slidesToShow:1,
            dots:true,
            arrows:false,
          }
        }
      ]

    })

})


require("trix")
require("@rails/actiontext")




